// Frontend: SecureYourAccount.js
import React from 'react';
import { useNavigate } from 'react-router-dom';

const SecureYourAccount = () => {
  const navigate = useNavigate();

  const handleContinue = () => {
    navigate('/2fa-setup');
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-md shadow-md text-center">
        <h1 className="text-3xl font-bold text-gray-800">Secure Your Account</h1>
        <hr className="my-4" />
        <p className="text-gray-700">
          You are required to enroll in Multi-Factor Authentication (MFA) for an extra layer of
          protection for your Option Prodigy account.
        </p>
        <p className="text-gray-700">
          Enroll using Google Authenticator, Microsoft Authenticator, or your favorite app.
        </p>
        <p className="text-gray-700">Remember to save your backup verification code.</p>
        <button
          onClick={handleContinue}
          className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default SecureYourAccount;
