// CreateTrade.js
import React, { useState } from 'react';
import api from '../services/api'; // Import the API service
import Button from './Button'; // Import the Button component
import Input from './Input'; // Import the Input component

const CreateTrade = () => {
  const [ticker, setTicker] = useState('');
  const [tradeDate, setTradeDate] = useState('');
  const [instrumentType, setInstrumentType] = useState('');
  const [strikePrice, setStrikePrice] = useState('');
  const [putCall, setPutCall] = useState('');
  const [positionEffect, setPositionEffect] = useState('');
  const [amount, setAmount] = useState('');
  const [price, setPrice] = useState('');
  const [commissions, setCommissions] = useState(''); // New field for fees
  // const [netAmount, setNetAmount] = useState('');
  const [strategy, setStrategy] = useState('');
  const [strategyNumber, setStrategyNumber] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [message, setMessage] = useState(''); // New state for messages
  const [loading, setLoading] = useState(false); // New state for loading indicator

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mapping for instrumentType
    const instrumentTypeMap = {
      'Stocks': 'COMMON_STOCK',
      'ETFs': 'EXCHANGE_TRADED_FUND',
      'Options': 'VANILLA',
    };

    // Get the mapped value for instrumentType
    const mappedInstrumentType = instrumentTypeMap[instrumentType] || instrumentType;

    // Ensure amount and price are numbers
    const parsedAmount = parseFloat(amount);
    const parsedPrice = parseFloat(price);

    if (isNaN(parsedAmount) || isNaN(parsedPrice)) {
      setMessage('Amount and Price must be valid numbers.');
      return;
    }

    // Initialize netAmount and amountValue
    let netAmount = null;
    let amountValue = parsedAmount;


    // Logic for calculating netAmount and handling amount sign based on positionEffect
    if ((instrumentType === 'ETFs' || instrumentType === 'Stocks') && positionEffect === 'OPENING') {
      netAmount = (parsedAmount * parsedPrice) * -1; // Opening for ETFs or Stocks
    } else if ((instrumentType === 'ETFs' || instrumentType === 'Stocks') && positionEffect === 'CLOSING') {
      netAmount = parsedAmount * parsedPrice; // Closing for ETFs or Stocks
      amountValue = parsedAmount * -1; // Flip amount sign
    } else if (instrumentType === 'Options' && positionEffect === 'OPENING' && (strategy === 'Long Call' || strategy === 'Long Put')) {
      netAmount = (parsedAmount * parsedPrice) * -100; // Opening for Options (Long Call/Long Put)
    } else if (instrumentType === 'Options' && positionEffect === 'CLOSING' && (strategy === 'Long Call' || strategy === 'Long Put')) {
      netAmount = (parsedAmount * parsedPrice) * 100; // Closing for Options (Long Call/Long Put)
      amountValue = parsedAmount * -1; // Flip amount sign
    } else if (instrumentType === 'Options' && positionEffect === 'OPENING' && (strategy === 'Cash Secured Put' || strategy === 'Covered Call' || strategy === 'Wheeling')) {
      netAmount = (parsedAmount * parsedPrice) * 100; // Opening for Cash Secured Put/Covered Call/Wheeling
      amountValue = parsedAmount * -1; // Flip amount sign
    } else if (instrumentType === 'Options' && positionEffect === 'CLOSING' && (strategy === 'Cash Secured Put' || strategy === 'Covered Call' || strategy === 'Wheeling')) {
      netAmount = (parsedAmount * parsedPrice) * -100; // Closing for Cash Secured Put/Covered Call/Wheeling
    }

      
    // Create the trade object
    const tradeData = {
      underlyingSymbol: ticker,
      tradeDate,
      instrumentType: mappedInstrumentType,  // Use the mapped value
      strikePrice: strikePrice !== '' ? strikePrice : null, // Convert empty string to null
      putCall,
      positionEffect,
      amount: amountValue !== '' ? amountValue : null, // Convert empty string to null
      price: price !== '' ? price : null, // Convert empty string to null
      commissions: commissions !== '' ? commissions : null, // Convert empty string to null
      netAmount,
      strategy: strategy ? strategy : null,
      strategyNumber: strategyNumber ? strategyNumber: null,
      expirationDate: expirationDate ? expirationDate : null,  // Check for invalid expirationDate
      source: 'Manually created', // Automatically add source
    };

    try {
      await api.post('/trades/create', tradeData);
      setMessage('Trade successfully created!'); // Set success message
      resetForm(); // Reset form after successful trade creation
      setTimeout(() => setMessage(''), 5000); // Clear message after 3 seconds
    } catch (error) {
      console.error(error);
      setMessage('Failed to create trade.'); // Set error message
      setTimeout(() => setMessage(''), 5000); // Clear message after 3 seconds
    } finally {
      setLoading(false); // Set loading to false after completion
    }
  };

    // Function to reset the form fields
    const resetForm = () => {
      setTicker('');
      setTradeDate('');
      setInstrumentType('');
      setStrikePrice('');
      setPutCall('');
      setPositionEffect('');
      setAmount('');
      setPrice('');
      setCommissions('');
      setStrategy('');
      setStrategyNumber('');
      setExpirationDate('');
    };

//   return (
//     <div className="min-h-screen flex items-center justify-center px-4 mt-10">
//       <div className="bg-white p-8 rounded-lg shadow-md w-full max-w-lg">
//         <h2 className="text-3xl font-semibold text-gray-800 mb-8 text-center">Create Trade</h2>
//         <form onSubmit={handleSubmit} className="space-y-6">
//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Ticker (Required)</label>
//             <Input
//               type="text"
//               value={ticker}
//               onChange={(e) => setTicker(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Date (Required)</label>
//             <Input
//               type="date"
//               value={tradeDate}
//               onChange={(e) => setTradeDate(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Type (Required)</label>
//             <select
//               value={instrumentType}
//               onChange={(e) => setInstrumentType(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             >
//               <option value="">Select Type</option>
//               <option value="ETFs">ETFs</option>
//               <option value="Options">Options</option>
//               <option value="Stocks">Stocks</option>
//             </select>
//           </div>


//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Open/Close (Required)</label>
//             <select
//               value={positionEffect}
//               onChange={(e) => setPositionEffect(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             >
//               <option value="">Select Position</option>
//               <option value="OPENING">OPENING</option>
//               <option value="CLOSING">CLOSING</option>
//             </select>
//           </div>
//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Quantity (Required)</label>
//             <Input
//               type="number"
//               min="0" // Ensure the minimum value is 0 to prevent negative values
//               value={amount}
//               onChange={(e) => setAmount(e.target.value > 0 ? e.target.value : '')} // Prevent negative numbers
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Price Filled (Required)</label>
//             <Input
//               type="number"
//               min="0" // Ensure the minimum value is 0 to prevent negative values
//               value={price}
//               onChange={(e) => setPrice(e.target.value > 0 ? e.target.value : '')}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             />
//           </div>


//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Fees</label>
//             <Input
//               type="number"
//               step="0.01"
//               value={commissions}
//               onChange={(e) => setCommissions(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//             />
//           </div>

//         {/* Conditionally render these fields if Options is selected */}
//         {instrumentType === 'Options' && (
//           <>
//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Strike Price (Required)</label>
//             <Input
//               type="number"
//               min="0" // Ensure the minimum value is 0 to prevent negative values
//               value={strikePrice}
//               onChange={(e) => setStrikePrice(e.target.value > 0 ? e.target.value : '')}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             />
//           </div>
//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Put/Call (Required)</label>
//             <select
//               value={putCall}
//               onChange={(e) => setPutCall(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             >
//               <option value="">Select Put/Call</option>
//               <option value="Put">Put</option>
//               <option value="Call">Call</option>
//             </select>
//           </div>


//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Strategy (Required)</label>
//             <select
//               value={strategy}
//               onChange={(e) => setStrategy(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             >
//               <option value="">Select Strategy</option>
//               <option value="Long Call">Long Call</option>
//               <option value="Long Put">Long Put</option>
//               {/* <option value="Iron Condor">Iron Condor</option> */}
//               <option value="Covered Call">Covered Call</option>
//               {/* <option value="Straddle">Straddle</option>
//               <option value="Strangle">Strangle</option> */}
//               <option value="Wheeling">Wheeling</option>
//               <option value="Cash Secured Put">Cash Secured Put</option>
//               {/* <option value="Calendar Spread">Calendar Spread</option> */}
//             </select>
//           </div>

//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Strategy Number (Required)</label>
//             <Input
//               type="number"
//               value={strategyNumber}
//               onChange={(e) => setStrategyNumber(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             />
//           </div>

//           <div>
//             <label className="block text-gray-700 text-sm mb-2">Expiration Date (Required)</label>
//             <Input
//               type="date"
//               value={expirationDate}
//               onChange={(e) => setExpirationDate(e.target.value)}
//               className="w-full px-4 py-2 bg-gray-200 text-gray-900 rounded-lg focus:outline-none focus:ring focus:ring-blue-500"
//               required
//             />
//           </div>
//           </>
// )}

//           <Button
//             type="submit"
//             className="w-full p-3 bg-blue-600 text-white font-semibold rounded-lg hover:bg-blue-700 transition duration-200"
//             disabled={loading} // Disable button during submission
//           >
//             {loading ? 'Processing...' : 'Create Trade'}
//           </Button>
//         </form>
//         {message && <p className="text-gray-700 text-center mt-4">{message}</p>}
//       </div>
//     </div>
//   );
// };


return (
  <div className="flex items-center justify-center px-4 mt-10 mb-5">
    <div className="bg-white shadow-xl rounded-lg w-full max-w-3xl p-6">
      <h2 className="text-3xl font-semibold text-gray-800 mb-8 text-center">Create Trade</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Grid layout for form fields */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block text-gray-700 text-sm mb-2">Ticker <span className="text-red-500">*</span></label>
            <Input
              type="text"
              value={ticker}
              onChange={(e) => setTicker(e.target.value)}
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm mb-2">Date <span className="text-red-500">*</span></label>
            <Input
              type="date"
              value={tradeDate}
              onChange={(e) => setTradeDate(e.target.value)}
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm mb-2">Type <span className="text-red-500">*</span></label>
            <select
              value={instrumentType}
              onChange={(e) => setInstrumentType(e.target.value)}
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">Select Type</option>
              <option value="ETFs">ETFs</option>
              <option value="Options">Options</option>
              <option value="Stocks">Stocks</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 text-sm mb-2">Open/Close <span className="text-red-500">*</span></label>
            <select
              value={positionEffect}
              onChange={(e) => setPositionEffect(e.target.value)}
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            >
              <option value="">Select Position</option>
              <option value="OPENING">OPENING</option>
              <option value="CLOSING">CLOSING</option>
            </select>
          </div>
          <div>
            <label className="block text-gray-700 text-sm mb-2">Quantity <span className="text-red-500">*</span></label>
            <Input
              type="number"
              min="0"
              value={amount}
              onChange={(e) => setAmount(e.target.value > 0 ? e.target.value : '')}
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm mb-2">Price Filled <span className="text-red-500">*</span></label>
            <Input
              type="number"
              min="0"
              value={price}
              onChange={(e) => setPrice(e.target.value > 0 ? e.target.value : '')}
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label className="block text-gray-700 text-sm mb-2">Fees</label>
            <Input
              type="number"
              step="0.01"
              value={commissions}
              onChange={(e) => setCommissions(e.target.value)}
              className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          {/* Conditionally render these fields if Options is selected */}
          {instrumentType === 'Options' && (
            <>
              <div>
                <label className="block text-gray-700 text-sm mb-2">Strike Price <span className="text-red-500">*</span></label>
                <Input
                  type="number"
                  min="0"
                  value={strikePrice}
                  onChange={(e) => setStrikePrice(e.target.value > 0 ? e.target.value : '')}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">Put/Call <span className="text-red-500">*</span></label>
                <select
                  value={putCall}
                  onChange={(e) => setPutCall(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="">Select Put/Call</option>
                  <option value="Put">Put</option>
                  <option value="Call">Call</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">Strategy <span className="text-red-500">*</span></label>
                <select
                  value={strategy}
                  onChange={(e) => setStrategy(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                >
                  <option value="">Select Strategy</option>
                  <option value="Long Call">Long Call</option>
                  <option value="Long Put">Long Put</option>
                  <option value="Covered Call">Covered Call</option>
                  <option value="Wheeling">Wheeling</option>
                  <option value="Cash Secured Put">Cash Secured Put</option>
                </select>
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">Strategy Number <span className="text-red-500">*</span></label>
                <Input
                  type="number"
                  value={strategyNumber}
                  onChange={(e) => setStrategyNumber(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
              <div>
                <label className="block text-gray-700 text-sm mb-2">Expiration Date <span className="text-red-500">*</span></label>
                <Input
                  type="date"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  className="w-full px-4 py-2 bg-gray-50 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  required
                />
              </div>
            </>
          )}
        </div>
        {/* Submit Button */}
        <div className="text-center mt-8">
          <Button
            type="submit"
            className="w-full md:w-1/2 p-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-200"
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Create Trade'}
          </Button>
        </div>
      </form>
      {message && <p className="text-gray-700 text-center mt-4">{message}</p>}
    </div>
  </div>
);
};


export default CreateTrade;
