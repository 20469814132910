//App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home/Home';
import CreateTrade from './components/CreateTrade';
import RegistrationEmail from './components/RegistrationEmail'; // Import the new email registration component
import PasswordSetup from './components/PasswordSetup'; // Import the password setup component
// import Register from './components/Register';
import Login from './components/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Navbar from './components/Navbar/Navbar';
import ProtectedRoute from './components/ProtectedRoute';
import Transactions from './components/Transactions/Transactions'; // Import Transactions component
import TwoFactorSetup from './components/TwoFactorSetup'; // Import the new 2FA setup component
import ErrorPage from './components/ErrorPage'; // Import the ErrorPage component
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import ConfirmUsername from './components/ConfirmUsername'; // Import ConfirmUsername component
import ConfirmEmailReminder from './components/ConfirmEmailReminder'; // Import ConfirmEmailReminder component
// import EmailConfirmed from './components/EmailConfirmed'; // Import ConfirmEmailReminder component
import CheckEmail from './components/CheckEmail';
import ResendConfirmationEmail from './components/ResendConfirmationEmail';
import SecureYourAccount from './components/SecureYourAccount';
import TermsAndConditions from './components/TermsAndConditions'; // Import TermsAndConditions
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Footer from './components/Footer/Footer'; // Import the Footer component
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [lastActivity, setLastActivity] = useState(Date.now());
  
  const handleLogout = useCallback(() => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    window.location.href = '/login'; // Redirect to login after logout
  }, []);  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  // Start inactivity timer only when authenticated
  useEffect(() => {
    let intervalId;

    if (isAuthenticated) {
      const checkInactivity = () => {
        const now = Date.now();
        const elapsed = now - lastActivity;
        if (elapsed > 5 * 60 * 1000) { // 5 minutes in milliseconds
          handleLogout();
        }
      };

      intervalId = setInterval(checkInactivity, 1000); // Check inactivity every second
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isAuthenticated, lastActivity, handleLogout]);

  const handleActivity = () => {
    setLastActivity(Date.now());
  };

  useEffect(() => {
    if (isAuthenticated) {
      window.addEventListener('mousemove', handleActivity);
      window.addEventListener('keydown', handleActivity);
    }

    return () => {
      if (isAuthenticated) {
        window.removeEventListener('mousemove', handleActivity);
        window.removeEventListener('keydown', handleActivity);
      }
    };
  }, [isAuthenticated]);

  return (
    <Router>
      <div className="App">
        <Navbar handleLogout={handleLogout} />
          <div className="App-content">
            <Routes>
              {/* Public Routes */}
              <Route path="/" element={<Home />} />
              {/* <Route path="/register" element={<Register setIsAuthenticated={setIsAuthenticated} />} /> */}
              <Route path="/register" element={<RegistrationEmail />} /> {/* New Email Registration Route */}
              <Route path="/set-password" element={<PasswordSetup />} /> {/* Password Setup Route */}
              <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token" element={<ResetPassword />} />
              {/* <Route path="/2fa-setup" element={<TwoFactorSetup />} /> Add 2FA setup route */}
              <Route path="/check-email" element={<CheckEmail />} />
              {/* <Route path="/email-confirmed" element={<EmailConfirmed />} />  */}
              <Route path="/confirm-email-reminder" element={<ConfirmEmailReminder />} />
              <Route path="/resend-confirmation-email" element={<ResendConfirmationEmail />} />

              {/* Authenticated Routes */}
              <Route element={<AuthenticatedRoute />}>
                <Route path="/secure-your-account" element={<SecureYourAccount />} />
                <Route path="/2fa-setup" element={<TwoFactorSetup />} /> {/* 2FA Setup Route */}
                <Route path="/terms-and-conditions" element={<TermsAndConditions setIsAuthenticated={setIsAuthenticated}/>} />
                <Route path="/confirm-username" element={<ConfirmUsername setIsAuthenticated={setIsAuthenticated} />} /> {/* Add Confirm Username route */}
                {/* Add any other routes that require authentication but not full 2FA */}
              </Route>

              {/* Protected routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/create-trade" element={<CreateTrade />} />
                <Route path="/transactions" element={<Transactions />} /> {/* Add Transactions route */}
              </Route>

              {/* Error page as the last fallback route */}
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </div>

        {/* Include the Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
