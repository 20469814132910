import React from 'react';

const TickerWithLogo = ({ ticker }) => {
  // Construct the local logo path based on the ticker symbol
  const logoPath = `/logos/${ticker}.svg`; // Adjust extension if necessary
  const fallbackLogo = `/logos/default.svg`; // Fallback logo if the ticker logo doesn't exist

  return (
    <div className="ticker-with-logo">
      <img 
        src={logoPath} 
        alt={`${ticker} logo`} 
        className="logo"
        onError={(e) => { e.target.src = fallbackLogo }} // Fallback to default logo if not found
        style={{ width: '50px', height: '50px' }} // Adjust width and height to make the logo smaller
      />
    </div>
  );
};

export default TickerWithLogo;


