// ConfirmEmailReminder.js

import React, { useState } from 'react';
import axios from 'axios';

const ConfirmEmailReminder = () => {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Retrieve the email from localStorage
  const email = localStorage.getItem('email');

  const handleResendEmail = async () => {
    if (!email) {
      setError('No email found. Please try logging in again.');
      return;
    }

    setIsLoading(true);
    setMessage('');
    setError('');

    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/resend-confirmation-email`, {
        email, // Use the email from localStorage
      });

      setMessage('Confirmation email has been resent. Please check your inbox.');
    } catch (error) {
      setError('There was an issue resending the confirmation email. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-900">
      <div className="w-full max-w-md p-8 space-y-6 bg-gray-800 rounded-lg shadow-lg text-center">
        <h2 className="text-3xl font-bold text-white">Email Not Confirmed</h2>
        <p className="text-lg text-gray-300">
          Your email is not yet confirmed. Please check your inbox for the confirmation email.
        </p>
        <p className="text-lg text-gray-300">
          Didn’t receive the email? Click below to resend the confirmation email.
        </p>
        <button
          onClick={handleResendEmail}
          disabled={isLoading}
          className={`mt-4 px-4 py-2 font-bold text-white rounded-md ${
            isLoading ? 'bg-gray-500' : 'bg-blue-600 hover:bg-blue-700'
          }`}
        >
          {isLoading ? 'Resending...' : 'Resend Confirmation Email'}
        </button>

        {/* Success or error message */}
        {message && <p className="mt-4 text-center text-green-500">{message}</p>}
        {error && <p className="mt-4 text-center text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export default ConfirmEmailReminder;

