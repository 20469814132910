// (axiosInstance) has custom logic for handling Schwab-related 401 errors:

import axios from 'axios';

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://127.0.0.1', // Base URL of your API
});

// Add a request interceptor to include the token in headers
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token from localStorage
    const token = localStorage.getItem('token');
    
    // If the token exists, include it in the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    return config; // Return the modified config
  },
  (error) => {
    // Handle the error
    return Promise.reject(error);
  }
);

// Add an interceptor for handling 401 responses
axiosInstance.interceptors.response.use(
  (response) => {
      alert('Transactions successfully refreshed!');
      return response
    }, // If the response is successful, return it
  (error) => {
    // Handle 401 errors
    if (error.response && error.response.status === 401) {
      if (error.config.url.includes('/schwab/refresh')) {
        // Handle Schwab-related 401 error without logging out
        alert('Please re-authenticate with Schwab by clicking "Sync Schwab Live Data.');
        return Promise.reject(error); // Prevent logout
      } else {
        // For other 401 errors, log the user out
        window.location.href = '/login'; // Redirect to login page
      }
    }
    return Promise.reject(error); // For other errors, just pass the error forward
  }
);

export default axiosInstance;
