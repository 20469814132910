// Footer.js
import React from 'react';
import './Footer.css'; // Import the CSS for styling

const Footer = () => (
  <footer className="footer">
    <div className="footer-container">
      {/* Move the links first */}
      <ul className="footer-links">
        <li><a href="/terms">Terms</a></li>
        <li><a href="/privacy">Privacy</a></li>
        <li><a href="/security">Security</a></li>
        {/* <li><a href="/status">Status</a></li>
        <li><a href="/docs">Docs</a></li> */}
        {/* Add more links as needed */}
      </ul>
      {/* Then the copyright */}
      <p>© {new Date().getFullYear()} Option Prodigy</p>
    </div>
  </footer>
);

export default Footer;
