// import React, { useState, useEffect } from 'react';
// import { useLocation, useNavigate, Link } from 'react-router-dom';
// import './Navbar.css';

// const Navbar = ({ isAuthenticated, handleLogout }) => {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);
//   const [disableTransition, setDisableTransition] = useState(false);
//   const location = useLocation();
//   const navigate = useNavigate();

//   // Retrieve and decode the token from localStorage
//   const token = localStorage.getItem('token');
//   let decodedToken = null;
  
//   if (token) {
//     try {
//       const parts = token.split('.');
//       if (parts.length === 3) {
//         decodedToken = JSON.parse(atob(parts[1]));
//       } else {
//         console.error('Invalid JWT format');
//       }
//     } catch (err) {
//       console.error('Failed to decode token:', err);
//       localStorage.removeItem('token');
//     }
//   }

//   // Check if 2FA is completed
//   const isTwoFaVerified = decodedToken?.twoFaVerified === true;
//   const isTwoFaSetupCompleted = decodedToken?.twoFaSetupCompleted === true;

//   const pathExists = (path) => {
//     const validPaths = [
//       '/',
//       '/login',
//       '/register',
//       '/dashboard',
//       '/create-trade',
//       '/transactions',
//       '/2fa-setup',
//       '/forgot-password',
//       '/reset-password/:token',
//       '/confirm-username',
//       '/confirm-email-reminder',
//     ];
//     return validPaths.includes(path);
//   };

// // Handle navigation with custom logic
// const handleNavigation = (e, path) => {
//   e.preventDefault(); // Prevent default link behavior

//   // If the user is on the 2FA setup page and tries to navigate away without completing setup
//   if (
//     location.pathname === '/2fa-setup' &&
//     (!isTwoFaSetupCompleted || !isAuthenticated)
//   ) {
//     alert('You must complete 2FA setup to use the website.');
//     navigate('/2fa-setup');
//   } else if (location.pathname === '/2fa-setup' && !pathExists(path)) {
//     // If the user tries to navigate to a non-existent page from /2fa-setup
//     alert('This page does not exist. Please complete your 2FA setup.');
//     navigate('/2fa-setup');
//   } else if (!isTwoFaVerified && isAuthenticated && isTwoFaSetupCompleted) {
//     // If 2FA is not verified during login, block navigation to protected routes
//     alert('You must complete 2FA verification to access this page.');
//     navigate('/login');
//   } else {
//     // Allow navigation for valid routes
//     navigate(path);
//   }

//   // Close the menu on navigation (for mobile menu)
//   if (isMenuOpen) {
//     setIsMenuOpen(false);
//   }
// };

//   const toggleMenu = () => {
//     setIsMenuOpen(!isMenuOpen);
//   };

//   const closeMenu = () => {
//     setIsMenuOpen(false);
//   };


//   const isAuthPage = location.pathname === '/login' || location.pathname === '/2fa-setup' || location.pathname === '/register';

//   // Close menu when resizing to large screens and disable transition during resize
//   useEffect(() => {
//     let resizeTimer;
//     const handleResize = () => {
//       // Add 'notransition' class to disable transitions
//       setDisableTransition(true);

//       clearTimeout(resizeTimer);
//       resizeTimer = setTimeout(() => {
//         // Re-enable transitions after resizing has stopped
//         setDisableTransition(false);
//       }, 100);

//       if (window.innerWidth > 768 && isMenuOpen) {
//         setIsMenuOpen(false);
//       }
//     };

//     window.addEventListener('resize', handleResize);

//     // Clean up the event listener on unmount
//     return () => {
//       window.removeEventListener('resize', handleResize);
//       clearTimeout(resizeTimer);
//     };
//   }, [isMenuOpen]);

//   // Prevent scrolling when the menu is open
//   useEffect(() => {
//     if (isMenuOpen) {
//       document.body.classList.add('no-scroll');
//     } else {
//       document.body.classList.remove('no-scroll');
//     }
//   }, [isMenuOpen]);

//   // Function to handle key presses on interactive divs
//   const handleKeyDown = (event, action) => {
//     if (event.key === 'Enter' || event.key === ' ') {
//       event.preventDefault();
//       action();
//     }
//   };

//   return (
//     <nav className={`navbar ${isMenuOpen ? 'menu-open' : ''}`}>
//       {/* Logo for large screens */}
//       <Link
//         to="/"
//         className="navbar-logo"
//         onClick={(e) => handleNavigation(e, '/')}
//       >
//         Option Prodigy
//       </Link>

//       {/* Hamburger icon */}
//       <div
//         className="hamburger"
//         onClick={toggleMenu}
//         tabIndex="0"
//         role="button"
//         aria-label="Open menu"
//         onKeyDown={(e) => handleKeyDown(e, toggleMenu)}
//       >
//         <div></div>
//         <div></div>
//         <div></div>
//       </div>

//       {/* Navbar menu */}
//       <ul
//         className={`navbar-menu ${
//           disableTransition ? 'notransition' : ''
//         }`}
//       >

//         {/* Menu header with logo and close button */}
//         <li className="menu-header">
//           <Link
//             to="/"
//             className="menu-logo"
//             onClick={(e) => handleNavigation(e, '/')}
//           >
//             Option Prodigy
//           </Link>
//           <div
//             className="close-button"
//             onClick={closeMenu}
//             tabIndex="0"
//             role="button"
//             aria-label="Close menu"
//             onKeyDown={(e) => handleKeyDown(e, closeMenu)}
//           >
//             &times;
//           </div>
//         </li>

//       {/* Menu items */}
//         {isAuthenticated &&
//         isTwoFaSetupCompleted &&
//         isTwoFaVerified &&
//         !isAuthPage ? (
//           <>
//             <li>
//               <Link
//                 to="/dashboard"
//                 onClick={(e) => handleNavigation(e, '/dashboard')}
//               >
//                 Dashboard
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/create-trade"
//                 onClick={(e) => handleNavigation(e, '/create-trade')}
//               >
//                 Create Trade
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/transactions"
//                 onClick={(e) => handleNavigation(e, '/transactions')}
//               >
//                 Transactions
//               </Link>
//             </li>
//             <li>
//               <button onClick={handleLogout} className="logout-button">
//                 Logout
//               </button>
//             </li>
//           </>
//         ) : (
//           <>
//             <li>
//               <Link
//                 to="/login"
//                 className="login-button"
//                 onClick={(e) => handleNavigation(e, '/login')}
//               >
//                 Login
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/register"
//                 className="get-started-button"
//                 onClick={(e) => handleNavigation(e, '/register')}
//               >
//                 Get Started
//               </Link>
//             </li>
//           </>
//         )}
//       </ul>
//     </nav>
//   );
// };

// export default Navbar;




// Navbar.js
import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Use jwt-decode for safer decoding
import './Navbar.css';

const Navbar = ({ handleLogout }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [disableTransition, setDisableTransition] = useState(false);
  // const location = useLocation();
  const navigate = useNavigate();

  // Retrieve and decode the token from localStorage
  const token = localStorage.getItem('token');
  let decodedToken = null;
  let isAuthenticated = false;
  let isTwoFaVerified = false;
  let isTwoFaSetupCompleted = false;
  let isTermsAccepted = false;
  let isUsernameConfirmed = false;

  if (token) {
    try {
      decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds

      if (decodedToken.exp > currentTime) {
        isAuthenticated = true;
        isTwoFaVerified = decodedToken.twoFaVerified === true;
        isTwoFaSetupCompleted = decodedToken.twoFaSetupCompleted === true;
        isTermsAccepted = decodedToken.termsAccepted === true;
        isUsernameConfirmed = decodedToken.usernameConfirmed === true;
        // You can include other flags as needed
      } else {
        // Token expired
        localStorage.removeItem('token');
      }
    } catch (err) {
      console.error('Failed to decode token:', err);
      localStorage.removeItem('token');
    }
  }

  const shouldShowAuthenticatedMenu =
  isAuthenticated &&
  isTwoFaSetupCompleted &&
  isTwoFaVerified &&
  isTermsAccepted &&
  isUsernameConfirmed;

  // Function to handle navigation with custom logic
  const handleNavigation = (e, path) => {
    e.preventDefault();

    // Add your custom navigation logic here if needed
    navigate(path);

    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  // const isAuthPage =
  //   location.pathname === '/login' ||
  //   location.pathname === '/2fa-setup' ||
  //   location.pathname === '/register';

  // Close menu when resizing to large screens and disable transition during resize
  useEffect(() => {
    let resizeTimer;
    const handleResize = () => {
      setDisableTransition(true);

      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        setDisableTransition(false);
      }, 100);

      if (window.innerWidth > 768 && isMenuOpen) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimer);
    };
  }, [isMenuOpen]);

  // Prevent scrolling when the menu is open
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isMenuOpen]);

  // Function to handle key presses on interactive divs
  const handleKeyDown = (event, action) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      action();
    }
  };

  return (
    <nav className={`navbar ${isMenuOpen ? 'menu-open' : ''}`}>
      {/* Logo for large screens */}
      <Link
        to="/"
        className="navbar-logo"
        onClick={(e) => handleNavigation(e, '/')}
      >
        Option Prodigy
      </Link>

      {/* Hamburger icon */}
      <div
        className="hamburger"
        onClick={toggleMenu}
        tabIndex="0"
        role="button"
        aria-label="Open menu"
        onKeyDown={(e) => handleKeyDown(e, toggleMenu)}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>

      {/* Navbar menu */}
      <ul
        className={`navbar-menu ${
          disableTransition ? 'notransition' : ''
        }`}
      >
        {/* Menu header with logo and close button */}
        <li className="menu-header">
          <Link
            to="/"
            className="menu-logo"
            onClick={(e) => handleNavigation(e, '/')}
          >
            Option Prodigy
          </Link>
          <div
            className="close-button"
            onClick={closeMenu}
            tabIndex="0"
            role="button"
            aria-label="Close menu"
            onKeyDown={(e) => handleKeyDown(e, closeMenu)}
          >
            &times;
          </div>
        </li>

        {/* Menu items */}
        {shouldShowAuthenticatedMenu ? (
          <>
            <li>
              <Link
                to="/dashboard"
                onClick={(e) => handleNavigation(e, '/dashboard')}
              >
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                to="/create-trade"
                onClick={(e) => handleNavigation(e, '/create-trade')}
              >
                Create Trade
              </Link>
            </li>
            <li>
              <Link
                to="/transactions"
                onClick={(e) => handleNavigation(e, '/transactions')}
              >
                Transactions
              </Link>
            </li>
            <li>
              <button onClick={handleLogout} className="logout-button">
                Logout
              </button>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link
                to="/login"
                className="login-button"
                onClick={(e) => handleNavigation(e, '/login')}
              >
                Login
              </Link>
            </li>
            <li>
              <Link
                to="/register"
                className="get-started-button"
                onClick={(e) => handleNavigation(e, '/register')}
              >
                Get Started
              </Link>
            </li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navbar;
