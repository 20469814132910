//ProtectedRoute.js
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';


const ProtectedRoute = () => {
  const token = localStorage.getItem('token'); // Retrieve the token

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds

      if (decodedToken.exp > currentTime) {
        const isTwoFaVerified = decodedToken.twoFaVerified;
        const isTwoFaSetupCompleted = decodedToken.twoFaSetupCompleted;
        const isUsernameConfirmed = decodedToken.usernameConfirmed;
        const isTermsAccepted = decodedToken.termsAccepted;

        if (isTwoFaSetupCompleted && isTwoFaVerified && isUsernameConfirmed && isTermsAccepted) {
          return <Outlet />;
        } else {
          // Redirect to appropriate onboarding step
          if (!isTwoFaSetupCompleted) {
            return <Navigate to="/secure-your-account" replace />;
          } else if (!isTwoFaVerified) {
            return <Navigate to="/login" replace />;
          } else if (!isTermsAccepted) {
            return <Navigate to="/terms-and-conditions" replace />;
          } else if (!isUsernameConfirmed) {
            return <Navigate to="/confirm-username" replace />;
          } else {
            return <Navigate to="/login" replace />;
          }
        }
      } else {
        // Token expired
        localStorage.removeItem('token');
        return <Navigate to="/login" replace />;
      }
    } catch (error) {
      // Invalid token
      localStorage.removeItem('token');
      return <Navigate to="/login" replace />;
    }
  } else {
    // No token
    return <Navigate to="/login" replace />;
  }
};

//   // Decode the token if it exists
//   const decodedToken = token ? JSON.parse(atob(token.split('.')[1])) : null;

//   // Check if the token is valid and if 2FA has been verified
//   const isTwoFaVerified = decodedToken?.twoFaVerified;
//   const isTwoFaSetupCompleted = decodedToken?.twoFaSetupCompleted; // Check if 2FA setup was completed


//   // If the token exists and 2FA is verified, grant access to protected routes
//   if (token && isTwoFaSetupCompleted && isTwoFaVerified) {
//     return <Outlet />;
//   } 
//   // Instead of redirecting, just show a message temporarily.
//   else {
//     return <div>
//       <h2>Token missing or invalid.</h2>
//       <p>isTwoFaVerified: {isTwoFaVerified ? 'Yes' : 'No'}</p>
//       <p>isTwoFaSetupCompleted: {isTwoFaSetupCompleted ? 'Yes' : 'No'}</p>
//       <p>Token: {token ? 'Exists' : 'Does not exist'}</p>
//       {/* You can include more details about the token or session here */}
//     </div>;
//   }
// };

export default ProtectedRoute;


