// CheckEmail.js

import React from 'react';
import { useLocation, Link } from 'react-router-dom';

const CheckEmail = () => {
  const location = useLocation();
  const { email } = location.state || {};

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-md text-center">
        <h1 className="text-3xl font-bold text-gray-800">Almost there...</h1>
        <p className="text-gray-700">
          Please check your email <strong>{email}</strong> to confirm your account.
        </p>
        <p className="text-gray-700">
          If <strong>{email}</strong> is not your email address, please{' '}
          <Link to="/register" className="text-blue-600 hover:text-blue-700 font-semibold">
            go back
          </Link>{' '}
          and enter the correct one.
        </p>
        <p className="text-gray-700">
          If you haven't received our email in 15 minutes, please check your spam folder.
        </p>
      </div>
    </div>
  );
};

export default CheckEmail;
