// AuthenticatedRoute.js
import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const AuthenticatedRoute = () => {
  const token = localStorage.getItem('token');

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // in seconds

      if (decodedToken.exp > currentTime) {
        const isTwoFaSetupCompleted = decodedToken.twoFaSetupCompleted;
        const isTwoFaVerified = decodedToken.twoFaVerified;
        const isTermsAccepted = decodedToken.termsAccepted;
        const isUsernameConfirmed = decodedToken.usernameConfirmed;

        if (!isTwoFaSetupCompleted) {
          return <Outlet />;
        } else if (!isTwoFaVerified) {
          return <Outlet />;
        } else if (!isTermsAccepted) {
          return <Outlet />;
        } else if (!isUsernameConfirmed) {
          return <Outlet />;
        } else {
          return <Navigate to="/dashboard" replace />;
        }
      } else {
        // Token expired
        localStorage.removeItem('token');
        return <Navigate to="/login" replace />;
      }
    } catch (error) {
      // Invalid token
      localStorage.removeItem('token');
      return <Navigate to="/login" replace />;
    }
  } else {
    // No token
    return <Navigate to="/login" replace />;
  }
};

export default AuthenticatedRoute;
