// ResendConfirmationEmail.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation, useNavigate, Link } from 'react-router-dom';

const ResendConfirmationEmail = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Optional: Display a message if redirected due to invalid/expired token
  const [infoMessage, setInfoMessage] = useState(<></>);

  useEffect(() => {
    if (location.state?.fromExpiredToken) {
      setInfoMessage(
        'Your confirmation link has expired or is invalid. Please enter your email to receive a new confirmation link.'
      );
    }
  }, [location.state]);

  const handleResend = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/resend-confirmation-email`, {
        email,
      });
      // Navigate to the CheckEmail page and pass the email
      navigate('/check-email', { state: { email } });
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Error resending confirmation email.';
      const errorCode = error.response?.data?.code;

      if (errorCode === 'EMAIL_NOT_FOUND') {
        // Display the message with a link to the /register page
        setInfoMessage(
          <>
            Email not found. Please{' '}
            <Link to="/register" className="text-blue-600 hover:text-blue-700 font-semibold">
              sign up here
            </Link>
            .
          </>
        );
      } else if (errorCode === 'EMAIL_CONFIRMED') {
        // If the email is already confirmed, prompt the user to log in
        setInfoMessage(
          <>
            This email is already confirmed. Please{' '}
            <Link to="/login" className="text-blue-600 hover:text-blue-700 font-semibold">
              log in here
            </Link>
            .
          </>
        );
      } else {
        // Handle other errors
        setInfoMessage(errorMsg);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-md">
        <h1 className="text-3xl font-bold text-gray-800 text-center">Resend Confirmation Email</h1>
        {infoMessage && <p className="text-center text-gray-600">{infoMessage}</p>}
        <form className="space-y-4" onSubmit={handleResend}>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 mt-1 text-gray-900 border border-gray-300 rounded-md"
              placeholder="you@example.com"
            />
          </div>
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Resend Email
          </button>
        </form>
      </div>
    </div>
  );
};

export default ResendConfirmationEmail;
