//RegistrationEmail.js
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

const RegistrationEmail = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/register-email`, { email });
      // const { message } = response.data;

      // Redirect to the check email page and pass the email
      navigate('/check-email', { state: { email } });
    } catch (error) {
      // Handle specific error messages from the backend
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        if (errorMessage === 'Email is already registered and confirmed. Please log in.') {
          setMessage(errorMessage);
        } else {
          setMessage('Something went wrong. Please try again.');
        }
      } else {
        setMessage('Something went wrong. Please try again.');
      }
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-md">
        {/* Typewriter effect for the heading */}
        <h1 className="text-3xl font-bold text-gray-800 text-center">
          <Typewriter
            options={{
              strings: ['Sign up for your account'],
              autoStart: true,
              loop: true,
              delay: 50,
              deleteSpeed: 30,
            }}
          />
        </h1>

        <p className="text-center text-gray-500 text-sm">
          Enter your email below to get started.
        </p>

        <form className="space-y-4" onSubmit={handleEmailSubmit}>
          <div>
            <label className="block text-sm font-medium text-gray-700">Email address</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className="w-full px-4 py-2 mt-1 text-gray-900 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
              placeholder="you@example.com"
            />
          </div>

          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Sign up
          </button>
        </form>

        {/* {message && <p className="mt-4 text-center text-red-500">{message}</p>} */}


        {message && (
          <p className="mt-4 text-center text-red-500">
            {message}{' '}
            {message === 'Email is already registered and confirmed. Please log in.' && (
              <a href="/login" className="text-blue-600 hover:text-blue-700 font-semibold">
                Log in here
              </a>
            )}
          </p>
        )}      

        <div className="mt-6 text-center">
          <p className="text-gray-500">
            Already have an account?{' '}
            <a href="/login" className="text-blue-600 hover:text-blue-700 font-semibold">
              Sign in here
            </a>
          </p>
        </div>
      </div>

      <p className="mt-8 text-sm text-gray-400 text-center">
        By signing up, you agree to our{' '}
        <a href="/terms" className="text-blue-600 hover:text-blue-700">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="/privacy" className="text-blue-600 hover:text-blue-700">
          Privacy Policy
        </a>.
      </p>
    </div>
  );
};

export default RegistrationEmail;
