import React, { useState } from 'react';
import axios from 'axios';
import Button from './Button'; // Assuming you have a Button component
import Input from './Input'; // Assuming you have an Input component

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/forgot-password`, { email });
      setMessage('Password reset link sent! Please check your email.');
    } catch (err) {
      setError('Failed to send password reset link. Please try again.');
      console.error(err.response?.data || err.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-md">
        <h2 className="text-3xl font-bold text-center text-grey-800">Reset Password</h2>
        <p className="text-gray-700">
        Enter your email address below and we'll send you a link to reset your password.
        </p>
        <form className="space-y-4" onSubmit={handleForgotPasswordSubmit}>
          <div>
            <label className="block text-sm font-medium text-grey-700">Email</label>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <Button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Reset Password
          </Button>
        </form>
        {message && <p className="mt-4 text-center text-green-500">{message}</p>}
        {error && <p className="mt-4 text-center text-red-500">{error}</p>}
      </div>
    </div>
  );
};

export default ForgotPassword;
