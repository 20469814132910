// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';

// Home.js
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, A11y } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './Home.css'; // Import the CSS for styling

const Home = () => {
  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-left">
          <h1>
          Optimize Your Option Strategies with
            <span className="highlight"> Option Prodigy</span>
          </h1>
          <p>
          Automatically sync your investment transactions, calculate option cost basis, and see if your strategies are outperforming major indices like the S&P 500.
          </p>
          <Link to="/register" className="cta-button">
            Get started
          </Link>
        </div>

        <div className="hero-right">
        <DotLottieReact
          src="https://lottie.host/7706fafc-f0a6-4c98-8cb2-a28265699ec1/MWJv3gmv76.json"
          loop
          autoplay
          background="transparent" 
          speed="1" 
          // style={{width: '600px', height: '600px'}}
        />
        </div>
      </section>

      {/* Features Section */}
      <section className="features">
        <div className="feature-section">
          <div className="feature-text">
            <h2>Automatic Transaction Sync</h2>
            <p>Connect your brokerage accounts and automatically import your investment transactions.</p>
          </div>
          <div className="feature-animation">
          <DotLottieReact
              src="https://lottie.host/8acd9531-8c0c-4492-8233-ed20d97cfbad/vbH9KQlRbd.json"
              loop
              autoplay
              background="transparent" 
              speed="1" 
            />
          </div>
        </div>

        <div className="feature-section">
          <div className="feature-text">
            <h2>Calculate Option Cost Basis</h2>
            <p>Accurately calculate the cost basis for each of your option strategies with ease.</p>
          </div>
          <div className="feature-animation">
          <DotLottieReact
              src="https://lottie.host/5f4b6c53-6384-4aa0-91d6-9670f7328bfb/BJYXbiBYhv.json"
              loop
              autoplay
              background="transparent" 
              speed="1" 
            />
          </div>
        </div>

        <div className="feature-section">
          <div className="feature-text">
            <h2>Performance Comparison</h2>
            <p>See how your option strategies stack up against major indices like the S&P 500.</p>
          </div>
          <div className="feature-animation">
            <DotLottieReact
              src="https://lottie.host/9f3eaeb1-2447-4623-9e5d-4fc2426a1215/2vfxEGZMcz.json"
              loop
              autoplay
              background="transparent" 
              speed="1" 
   
            />
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2>What Our Users Say</h2>

        {/* Swiper Container */}
        <Swiper
          modules={[Navigation, Pagination, A11y]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            768: {
              slidesPerView: 3,
              spaceBetween: 30,
              allowTouchMove: false,
            },
          }}
          className="testimonials-swiper"
        >
          {/* Swiper Slides */}
          <SwiperSlide>
            <div className="testimonial-item">
              <p>
                "Option Prodigy has transformed the way I track my investments. The automatic syncing and performance insights are invaluable."
              </p>
              <h4>- Alex M.</h4>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="testimonial-item">
              <p>
                "Finally, a tool that helps me understand if my strategies are truly outperforming the market."
              </p>
              <h4>- Sarah K.</h4>
            </div>
          </SwiperSlide>

          <SwiperSlide>
            <div className="testimonial-item">
              <p>
                "The cost basis calculations are spot on. It saves me so much time during tax season."
              </p>
              <h4>- Michael B.</h4>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
    </div>
  );
};

export default Home;
