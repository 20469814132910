import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Button from './Button'; // Import the Button component
import Input from './Input'; // Import the Input component
import { useNavigate, Link } from 'react-router-dom';

const Login = ({ setIsAuthenticated }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFaCode, setTwoFaCode] = useState(''); // For 2FA code
  const [backupCode, setBackupCode] = useState(''); // For Backup Code input
  const [is2FARequired, setIs2FARequired] = useState(false); // Track if 2FA is needed
  const [isUsingBackupCode, setIsUsingBackupCode] = useState(false); // Track if user is using a backup code
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Function to clear the message on input change (login form)
  useEffect(() => {
    if (email || password || twoFaCode || backupCode) {
      setMessage('');
    }
  }, [email, password, twoFaCode, backupCode]);

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        email,
        password,
      });



      // // Check if 2FA setup is incomplete
      // if (!res.data.twoFaSetupCompleted) {
      //   localStorage.setItem('token', res.data.token); // Store the token
      //   setMessage(''); // Clear any previous error message
      //   navigate('/2fa-setup'); // Redirect to 2FA setup
      // } 
      // Handle response for incomplete 2FA setup
      if (res.data.needs2FASetup) {
        console.log("do we get here? secure?")
        localStorage.setItem('token', res.data.token); // Store the token
        setMessage(''); // Clear any previous error message
        navigate('/secure-your-account'); // Redirect to Secure Your Account page
      }
      // Check if 2FA is required after login
      else if (res.data.is2FARequired) {
        setIs2FARequired(true);
        localStorage.setItem('token', res.data.token); // Store the token
        setMessage(''); // Clear any previous error message
      } 
      // If 2FA setup is completed and 2FA is verified, proceed to dashboard
      else {
        localStorage.setItem('token', res.data.token); // Store the token
        setIsAuthenticated(true); // Update auth state
        setMessage(''); // Clear any previous error message
        navigate('/dashboard'); // Redirect to dashboard
      }
    } catch (err) {
      // Handle different error status codes
      const errorMessage = err.response?.data?.message || 'Login failed. Please check your credentials.';
      console.error('Error message:', errorMessage);
  
      // if (err.response?.status === 400 && errorMessage === 'Please confirm your email before logging in.') {
      //   localStorage.setItem('token', err.response.data.token); // Store the token
      //   localStorage.setItem('email', email); // Store email in localStorage
      //   setMessage(errorMessage);
      //   navigate('/confirm-email-reminder'); // Redirect to the email confirmation page
      // } else if (err.response?.status === 403 && errorMessage === '2FA setup incomplete. Please complete 2FA setup.') {
      //   localStorage.setItem('token', err.response.data.token); // Store the token
      //   setMessage(errorMessage);
      //   navigate('/2fa-setup'); // Redirect to 2FA setup page
      // } else {
      //   setMessage(errorMessage);
      // }
  //   }
  // };

    if (
      err.response?.status === 400 &&
      errorMessage === 'Please confirm your email before logging in.'
    ) {
      setMessage(errorMessage);
      navigate('/confirm-email-reminder'); // Redirect to the email confirmation page
    } else {
      setMessage(errorMessage);
    }
  }
};
  
  
  const handle2FASubmit = async (e) => {
    e.preventDefault();
  
    const token = localStorage.getItem('token'); // Get the token from local storage

  
    if (!token) {
      setMessage('No token found, please log in again.');
      return;
    }
  
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/2fa/verify`,
        { twoFaCode },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        }
      );


      // if (res.data.success && !res.data.needsUsernameConfirmation) {
      //   // Set a flag to indicate 2FA has been verified
      //   localStorage.setItem('token', res.data.token);
      //   localStorage.setItem('twoFaVerified', 'true');
      //   localStorage.setItem('twoFaSetupCompleted', 'true'); // Store the setup completion flag
      //   setIsAuthenticated(true); // Update auth state
      //   setMessage(''); // Clear the error message on successful verification
      //   navigate('/dashboard'); // Redirect to dashboard

      // } else if (res.data.success && res.data.needsUsernameConfirmation) {
      //     // Set a flag to indicate 2FA has been verified
      //     localStorage.setItem('token', res.data.token);
      //     localStorage.setItem('twoFaVerified', 'true');
      //     localStorage.setItem('twoFaSetupCompleted', 'true'); // Store the setup completion flag
      //     setIsAuthenticated(true); // Update auth state
      //     setMessage(''); // Clear the error message on successful verification
      //     navigate('/confirm-username', { state: { username: res.data.username } }); // Redirect to username confirmation page
        
      
      if (res.data.success) {
        // Store the new token
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          setIsAuthenticated(true); // Update auth state
        }
  
        // Decode the token to get user flags
        const decodedToken = jwtDecode(res.data.token);
        const termsAccepted = decodedToken.termsAccepted;
        const usernameConfirmed = decodedToken.usernameConfirmed;
  
        setMessage(''); // Clear any previous error message
  
        if (!termsAccepted) {
          navigate('/terms-and-conditions');
        } else if (!usernameConfirmed) {
          navigate('/confirm-username');
        } else {
          navigate('/dashboard');
        }
      } else {
        setMessage('2FA verification failed. Please try again.');
      }
    } catch (err) {
      setMessage('2FA verification failed.');
      console.error(err.response?.data || err.message);
    }
  };

  const handleBackupCodeSubmit = async (e) => {
    e.preventDefault();
  
    const token = localStorage.getItem('token'); // Get the token from local storage
  
    if (!token) {
      setMessage('No token found, please log in again.');
      return;
    }
  
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/use-backup-code`,
        { backupCode },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Include the token in the request header
          },
        }
      );
  
      // if (res.data.success && !res.data.needsUsernameConfirmation) {
      //   // Backup code verified, no need for username confirmation
      //   setMessage('Backup code verified successfully! Redirecting to dashboard...');
      //   localStorage.setItem('token', res.data.token); // Save the new token
      //   localStorage.setItem('twoFaVerified', 'true');
      //   localStorage.setItem('twoFaSetupCompleted', 'true'); // Store the setup completion flag
      //   setIsAuthenticated(true); // Update auth state
      //   navigate('/dashboard'); // Redirect to dashboard
      // } else if (res.data.success && res.data.needsUsernameConfirmation) {
      //   // Backup code verified but username confirmation is required
      //   localStorage.setItem('token', res.data.token); // Save the new token
      //   localStorage.setItem('twoFaVerified', 'true');
      //   localStorage.setItem('twoFaSetupCompleted', 'true'); // Store the setup completion flag
      //   setIsAuthenticated(true); // Update auth state
      //   navigate('/confirm-username', { state: { username: res.data.username } }); // Redirect to username confirmation page

    if (res.data.success) {
      // Store the new token
      if (res.data.token) {
        localStorage.setItem('token', res.data.token);
        setIsAuthenticated(true); // Update auth state
      }

      // Decode the token to get user flags
      const decodedToken = jwtDecode(res.data.token);
      const termsAccepted = decodedToken.termsAccepted;
      const usernameConfirmed = decodedToken.usernameConfirmed;

      setMessage(''); // Clear any previous error message

      if (!termsAccepted) {
        navigate('/terms-and-conditions');
      } else if (!usernameConfirmed) {
        navigate('/confirm-username');
      } else {
        navigate('/dashboard');
      }
    } else {
        setMessage('Backup code verification failed. Please try again.');
      }
    } catch (err) {
      setMessage('Backup code verification failed.');
      console.error(err.response?.data || err.message);
    }
  };

  const toggleBackupCodeInput = () => {
    setIsUsingBackupCode(!isUsingBackupCode);
  };
  

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="w-full max-w-md p-8 space-y-6 bg-white shadow-md rounded-md">
        <h2 className="text-3xl font-bold text-center text-gray-800 ">Login</h2>
        {!is2FARequired ? (
          <form className="space-y-4" onSubmit={handleLoginSubmit}>
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            <Button
              type="submit"
              className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              Login
            </Button>
          </form>
        ) : (
          <form className="space-y-4" onSubmit={isUsingBackupCode ? handleBackupCodeSubmit : handle2FASubmit}>
            {!isUsingBackupCode ? (
            <>
            <div>
              <label className="block text-sm font-medium text-grey-700">2FA Code</label>
              <Input
                type="text"
                value={twoFaCode}
                onChange={(e) => setTwoFaCode(e.target.value)}
                className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
            </>
            ) : (
              <>
                <div>
                  <label className="block text-sm font-medium text-grey-700">Backup Code</label>
                  <Input
                    type="text"
                    value={backupCode}
                    onChange={(e) => setBackupCode(e.target.value)}
                    className="w-full px-3 py-2 mt-1 text-gray-900 bg-gray-200 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </>
            )}

            <Button
              type="submit"
              className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              {isUsingBackupCode ? 'Verify Backup Code' : 'Verify 2FA'}
            </Button>

            <button
              type="button"
              onClick={toggleBackupCodeInput}
              className="w-full px-4 py-2 mt-4 text-sm text-gray-700 hover:text-gray-400"
            >
              {isUsingBackupCode ? 'Use 2FA Code' : 'Use Backup Code'}
            </button>

          </form>
        )}

        {/* Error message display */}
        {message && <p className="mt-4 text-center text-red-500">{message}</p>}

        {/* New section for signup and forgotten credentials */}
        <div className="mt-6 space-y-2 text-center">
          <p className="text-gray-700">
            No account yet?{' '}
            <Link to="/register" className="text-blue-600 hover:text-blue-700 font-semibold">
              Sign up here.
            </Link>
          </p>
          <p className="text-gray-300">
            <Link to="/forgot-password" className="text-blue-600 hover:text-blue-700 font-semibold">
              Forgot your password?
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
