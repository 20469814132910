// PasswordSetup.js (frontend)
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

const PasswordSetup = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [newsletter, setNewsletter] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    minLength: false,
    hasLettersNumbersSymbols: false,
    passwordsMatch: false,
  });
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState(''); // New state variable to store email
  const [error, setError] = useState(''); // For error messages
  const [isLoading, setIsLoading] = useState(true); // Loading state during email confirmation
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const token = params.get('token');

  useEffect(() => {
    const confirmEmail = async () => {
      if (!token) {
        // Redirect to resend confirmation email if token is missing
        navigate('/resend-confirmation-email');
        return;
      }

      try {
        // Confirm the email before allowing password setup
        await axios.post(`${process.env.REACT_APP_API_URL}/confirm-email`, { token });
        setIsLoading(false);
      } catch (err) {
        const errorCode = err.response?.data?.code;
        if (errorCode === 'INVALID_TOKEN' || errorCode === 'TOKEN_EXPIRED') {
          // Redirect to resend confirmation email
          navigate('/resend-confirmation-email', { state: { fromExpiredToken: true } });
        } else {
          const errorMsg = err.response?.data?.message || 'Email confirmation failed.';
          setError(errorMsg);
          setIsLoading(false);
        }
      }
    };

    confirmEmail();
  }, [token, navigate]);

  useEffect(() => {
    // Validate password requirements
    const minLength = password.length >= 8;
    const hasLettersNumbersSymbols = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d]).+$/.test(password);
    const passwordsMatch = password === confirmPassword && password !== '';

    setPasswordValidations({
      minLength,
      hasLettersNumbersSymbols,
      passwordsMatch,
    });
  }, [password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all password requirements are met
    const { minLength, hasLettersNumbersSymbols, passwordsMatch } = passwordValidations;
    if (!minLength || !hasLettersNumbersSymbols || !passwordsMatch) {
      setError('Please meet all the password requirements before proceeding.');
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/set-password`, { password, token, newsletter });
      setEmail(response.data.email); // Set the email from the response
      setMessage('Password set successfully!');
    } catch (error) {
      const errorMsg = error.response?.data?.message || 'Error setting password.';
      setError(errorMsg);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center flex-grow">
        <p className="text-xl">Loading...</p>
      </div>
    );
  }

  if (error && !passwordValidations.passwordsMatch) {
    // Don't show the error message when passwords don't match, as it's handled in the validation
    setError('');
  }

  if (error) {
    return (
      <div className="flex items-center justify-center flex-grow">
        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-md shadow-md text-center">
          <h2 className="text-2xl font-bold text-red-500">{error}</h2>
          {error === 'Confirmation token has expired.' && (
            <button
              onClick={() => navigate('/resend-confirmation-email')}
              className="mt-4 px-4 py-2 bg-green-600 text-white rounded-md"
            >
              Resend Confirmation Email
            </button>
          )}
        </div>
      </div>
    );
  }

  if (message) {
    // Display the welcome page
    return (
      <div className="flex items-center justify-center flex-grow">


        <div className="w-full max-w-md p-8 space-y-6 bg-white rounded-md shadow-md text-center">
          <h1 className="text-3xl font-bold text-gray-800">Welcome to Option Prodigy</h1>
          <hr className="my-4" />
          <p className="text-lg text-gray-700">
            A new account for <strong>{email}</strong> is all set up.
          </p>
          <button
            onClick={() => navigate('/login')}
            className="w-full px-4 py-2 font-bold text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Click Here to Proceed
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center flex-grow">
      <div className="w-full max-w-md p-8 space-y-4 bg-white shadow-md rounded-md">
        <h1 className="text-3xl font-bold text-gray-800 text-center">Set your password</h1>
        <p className="text-center text-gray-600">
          Create your password and log in to your Option Prodigy account.
        </p>

        <hr className="my-4" />

        <form className="space-y-4" onSubmit={handleSubmit}>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Create a new password <span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md"
              placeholder="Enter your new password"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">
              Password confirmation <span className="text-red-500">*</span>
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className="w-full px-4 py-2 mt-1 border border-gray-300 rounded-md"
              placeholder="Confirm your new password"
            />
          </div>

          {/* Password Requirements */}
          <div className="space-y-1">
            <p className="text-sm font-medium text-gray-700">Password requirements:</p>
            <div className="flex items-center text-sm text-gray-600">
              {passwordValidations.minLength ? (
                <span className="text-green-500">&#10003;</span>
              ) : (
                <span className="text-red-500">&#10007;</span>
              )}
              <span className="ml-2">Must be a minimum of 8 characters.</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              {passwordValidations.hasLettersNumbersSymbols ? (
                <span className="text-green-500">&#10003;</span>
              ) : (
                <span className="text-red-500">&#10007;</span>
              )}
              <span className="ml-2">Must contain letters, numbers, and symbols.</span>
            </div>
            <div className="flex items-center text-sm text-gray-600">
              {passwordValidations.passwordsMatch ? (
                <span className="text-green-500">&#10003;</span>
              ) : (
                <span className="text-red-500">&#10007;</span>
              )}
              <span className="ml-2">Passwords must match.</span>
            </div>
          </div>

          {/* Newsletter Checkbox */}
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="newsletter"
                name="newsletter"
                type="checkbox"
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
                className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
              />
            </div>
            <div className="ml-2 text-sm">
              <label htmlFor="newsletter" className="font-medium text-gray-700">
                Yes, I would like to receive the monthly newsletter from Option Prodigy. I may unsubscribe at any time.
              </label>
            </div>
          </div>

          {error && (
            <p className="text-red-500 text-sm">
              {error}
            </p>
          )}

          <button
            type="submit"
            className={`w-full px-4 py-2 font-bold text-white rounded-md ${
              passwordValidations.minLength &&
              passwordValidations.hasLettersNumbersSymbols &&
              passwordValidations.passwordsMatch
                ? 'bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
                : 'bg-gray-400 cursor-not-allowed'
            }`}
            disabled={
              !passwordValidations.minLength ||
              !passwordValidations.hasLettersNumbersSymbols ||
              !passwordValidations.passwordsMatch
            }
          >
            Set Password and Log In
          </button>
        </form>
      </div>
    </div>
  );
};

export default PasswordSetup;
